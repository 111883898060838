<template>
  <div class="mb-4 w-4/12 h-7  bg-gray-200 rounded-full dark:bg-gray-700">
    <div
      class="h-7 rounded-full"
      :style="{ width: width, backgroundColor : color}"
    />
  </div>
</template>

<script>
export default {

  props: {
    title: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }

  }

}
</script>

<style>

</style>
