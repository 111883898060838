<template>
  <div>
    <div
      class="absolute z-50 bg-white shadow rounded-md p-2 w-96"
      :style="measureTooltipStyle"
      v-show="showMeasureTooltip"
    >
      {{ measureTooltipContent }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-7 h-7"
        @click="popupVisible = true"
        v-show="hasPlay"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
        />
      </svg>
    </div>
    <playlist
      :popup-visible="popupVisible"
      @update:popupVisible="popupVisible = $event"
      :is-sum="false"
      :start-ability="abilityKey"
    />
  </div>
</template>

<script>
import Playlist from '@/components/Playlist.vue'

import { AbilityDescriptions } from '@/content/reportContent.json'
import { mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    hasPlay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Playlist
  },
  data () {
    return {
      popupVisible: false,
      abilityKey: '',
      measureTooltipStyle: {
        left: 0,
        top: 0
      },
      showMeasureTooltip: false,
      measureTooltipContent: '',
      plugin: {
        id: 'customHover',
        afterEvent: (chart, event, opts) => {
          const evt = event.event

          if (evt.type !== 'mousemove') {
            return
          }

          let found, label
          if (this.type === 'bar') {
            const res = this.findLabelBar(this.getLabelHitboxesBar(chart.scales.y), evt)
            found = res[0]
            label = res[1]
          } else if (this.type === 'spider') {
            const res = this.findLabelSpider(this.getLabelHitboxesSpider(chart.scales.r), evt)
            found = res[0]
            label = res[1]
          }

          this.showMeasureTooltip = false

          if (found) {
            this.showMeasureTooltip = true
            const key = (typeof label.label === 'object') ? label.label.join('').replace('  ', ' ') : label.label
            this.measureTooltipContent = AbilityDescriptions[key]
            this.abilityKey = key
            const position = chart.canvas.getBoundingClientRect()

            this.measureTooltipStyle.left = position.left + window.pageXOffset + label.x + 'px'
            this.measureTooltipStyle.top = (position.top + window.pageYOffset + label.y) + 'px'
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      mainMeasures: 'REPORT/getMainMeasures'
    })
  },
  methods: {
    getLabelHitboxesBar: s => s._labelItems.map((e, i) => ({
      x: e.translation[0] - s._labelSizes.widths[i],
      x2: e.translation[0] + s._labelSizes.widths[i] / 2,
      y: e.translation[1] - s._labelSizes.heights[i] / 2,
      y2: e.translation[1] + s._labelSizes.heights[i] / 2,
      label: e.label,
      index: i
    })),
    findLabelBar (labels, evt) {
      let found = false
      let res = null

      labels.forEach(label => {
        if (evt.x > label.x && evt.x < label.x2 && evt.y > label.y && evt.y < label.y2) {
          res = label
          found = true
        }
      })

      return [found, res]
    },
    getLabelHitboxesSpider: s => {
      const labels = s._pointLabels
      return s._pointLabelItems.map((e, i) => ({
        x: e.left,
        x2: e.right,
        y: e.top,
        y2: e.bottom,
        label: labels[i],
        index: i
      }))
    },
    findLabelSpider (labels, evt) {
      let found = false
      let res = null

      labels.forEach(label => {
        if (evt.x > label.x && evt.x < label.x2 && evt.y > label.y && evt.y < label.y2) {
          res = label
          found = true
        }
      })
      return [found, res]
    }
  }
}
</script>

<style>

</style>
