<template>
  <div>
    <div
      :class="{'flex flex-col mt-20':!isSubReport, 'flex flex-col mt-12':isSubReport}"
    >
      <h4 class="text-xl font-semibold">
        Candidate state
      </h4>
      <p class="text-lg font-normal py-1 mt-4 mb-10">
        Below states are based on candidate’s rating collected during the test session.
      </p>
      <p
        v-if="showSensitivity || isPdf"
        class="text-lg font-normal py-1 mt-4 mb-4"
      >
        The tests are sensitive and may be affected by candidate's state. Sensitivity and cognitive variation is highly individual.
      </p>
      <show-details-btn
        v-show="!isPdf"
        class="flex justify-end pb-3"
        v-model="showSensitivity"
        :is-sub-report-sensitivity="true"
        :main="true"
      />
      <state-summary-box
        title="Overall state"
        content="The test aims to reflect the capacity during optimal circumstances. Lower indicated state may affect the test result which should be considered when analysing."
        :state="stateCheck()"
      />
      <div :class="{'grid grid-cols-4 gap-x-0 gap-y-16 px-16 py-10':!isSubReport, 'grid grid-cols-4 gap-x-0 gap-y-4 px-16 py-10':isSubReport}">
        <div>
          <p
            :class="{'text-lg font-normal':!isSubReport, 'font-medium text-base lite-gray mx-8':isSubReport}"
          >
            Mood
          </p>
        </div>
        <div class="col-span-3 flex flex-row items-center">
          <progress-bar
            title="new"
            :color="progressColor(parseInt(mainCandidate.mood_level))"
            :width="percentage(mainCandidate.mood_level)"
          />
          <p :class="{'font-medium text-base lite-gray mx-8':!isSubReport, 'text-base font-normal leading-8 mx-8':isSubReport}">
            {{ moodLevel(parseInt(mainCandidate.mood_level)) }}
          </p>
        </div>
        <div>
          <p
            :class="{'text-lg font-normal':!isSubReport, 'font-medium text-base lite-gray mx-8':isSubReport}"
          >
            Motivation
          </p>
        </div>
        <div class="col-span-3 flex flex-row items-center">
          <progress-bar
            title="new"
            :color="progressColor(mainCandidate.motivational_level)"
            :width="percentage(mainCandidate.motivational_level)"
          />
          <p :class="{'font-medium text-base lite-gray mx-8':!isSubReport, 'text-base font-normal leading-8 mx-8':isSubReport}">
            {{ motivationLevel(mainCandidate.motivational_level) }}
          </p>
        </div>
        <div>
          <p
            :class="{'text-lg font-normal':!isSubReport, 'font-medium text-base lite-gray mx-8':isSubReport}"
          >
            Sleep
          </p>
        </div>
        <div class="col-span-3 flex flex-row items-center">
          <progress-bar
            title="new"
            :color="progressColor(mainCandidate.sleep_duration / 60)"
            :width="percentageSleep(mainCandidate.sleep_duration / 60)"
          />
          <p :class="{'font-medium text-base lite-gray mx-8':!isSubReport, 'text-base font-normal leading-8 mx-8':isSubReport}">
            {{ sleepLevel( mainCandidate.sleep_duration / 60) }} sleep | {{ parseFloat( mainCandidate.sleep_duration / 60).toFixed(1) }} hours
          </p>
        </div>
        <div>
          <p
            :class="{'text-lg font-normal':!isSubReport, 'font-medium text-base lite-gray mx-8':isSubReport}"
          >
            Tiredness
          </p>
        </div>
        <div class="col-span-3 flex flex-row items-center">
          <progress-bar
            title="new"
            :color="progressColorTires(parseInt(mainCandidate.tiredness_level))"
            :width="percentageTiredness(mainCandidate.tiredness_level)"
          />
          <p :class="{'font-medium text-base lite-gray mx-8':!isSubReport, 'text-base font-normal leading-8 mx-8':isSubReport}">
            {{ parseInt(mainCandidate.tiredness_level) > 6 ? 'Tired' : 'Not tired' }}
          </p>
        </div>
        <div>
          <p
            :class="{'text-lg font-normal':!isSubReport, 'font-medium text-base lite-gray mx-8':isSubReport}"
          >
            Environment
          </p>
        </div>
        <div class="col-span-3 flex flex-row items-center">
          <p :class="{'font-medium text-base lite-gray':!isSubReport, 'text-base font-normal leading-8':isSubReport}">
            {{ undisturbedEnvironment === "No" ? "Undisturbed" : "Disturbed" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import StateSummaryBox from './StateSummaryBox.vue'
import ProgressBar from './Progress.vue'
import ShowDetailsBtn from '@/modules/Scoreboard/components/ShowDetailsBtn.vue'

export default {

  components: {
    StateSummaryBox,
    ProgressBar,
    ShowDetailsBtn
  },
  props: {
    mainCandidate: { type: Object, required: true },
    isSubReport: { type: Boolean, default: false },
    testIssues: { type: Array, default: null },
    allIssues: { type: Array, default: null },
    isPdf: { type: Boolean, required: false }
  },

  data () {
    return {
      showSensitivity: false
    }
  },

  computed: {
    undisturbedEnvironment () {
      return (this.mainCandidate.undisturbedEnvironment) ? 'Yes' : 'No'
    }
  },

  methods: {
    sleepLevel (level) {
      let result = 'Low'
      if (level <= 3) result = 'Low'
      else if (level >= 4 && level <= 6) result = 'Adequate'
      else result = 'Good'

      return result
    },
    motivationLevel (level) {
      let result = 'Low motivation'
      if (level <= 3) result = 'Low'
      else if (level >= 4 && level <= 6) result = 'Neutral'
      else result = 'Good motivation'
      return result
    },
    moodLevel (level) {
      let result = 'Low mood'
      if (level <= 3) result = 'Low'
      else if (level >= 4 && level <= 6) result = 'Neutral'
      else result = 'Good mood'
      return result
    },
    progressColor (param) {
      let color = '#D31515'
      if (param <= 3) color = '#D31515'
      else if (param >= 4 && param <= 6) color = '#E3C10C'
      else color = '#46634F'
      return color
    },
    percentage (value) {
      value = parseInt(value)
      const rs = 10 * value
      return `${rs}%`
    },
    percentageTiredness (value) {
      value = parseInt(value)
      const rs = 100 - 10 * value
      return `${rs}%`
    },
    percentageSleep (value) {
      value = parseInt(value)
      if (value >= 8) {
        value = 10
      }
      const rs = 10 * value
      return `${rs}%`
    },
    stateCheck () {
      const state = this.mainCandidate.state
      this.state = state
      let result = 'green'
      if (state === 1) result = 'red'
      else if (state === 2) result = 'yellow'
      else if (state === 3 && this.testIssues.length <= 0) result = 'green'
      else if (state === 3 && this.testIssues.length > 0) result = 'orange'
      else if (state === 3 && this.allIssues.length > 0) result = 'orange'
      else result = 'green'
      return result
    },
    progressColorTires (param) {
      param = 10 - parseInt(param)
      let color = '#D31515'
      if (param <= 3) color = '#D31515'
      else if (param >= 4 && param <= 6) color = '#E3C10C'
      else color = '#46634F'
      return color
    }
  }
}
</script>
<style>
</style>
