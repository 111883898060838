<template>
  <div class="flex flex-col w-full h-full p-5">
    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
      <p>
        {{ content.description }}
      </p>
    </div>
    <div class="flex flex-col items-center justify-center mt-8">
      <img
        src="@/assets/reports/GAME_model.webp"
        alt=""
      >
    </div>
  </div>
</template>

<script>

import { GameAnalysis } from '@/content/reportContent.json'

export default {
  data () {
    return {
      content: GameAnalysis
    }
  }
}
</script>

<style>

</style>
