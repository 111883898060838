<template>
  <div
    class="absolute z-50 bg-white shadow border-2 rounded-md p-2 flex max-h-60 overflow-y-auto"
    style="width: 560px"
    :style="tooltipStyle"
    v-show="showTooltip"
    @mouseenter="mouseOnTooltip = true"
    @mouseleave="mouseOnTooltip = false; showTooltip = false"
  >
    <div class="mr-2 w-1/2">
      <p class="font-bold">
        Low:
      </p>
      <p
        v-if="typeof barTooltipContent.low === 'object'"
      >
        {{ barTooltipContent.low[0] }}<br>
        {{ barTooltipContent.low[1] }}
      </p>
      <p
        v-else
      >
        {{ barTooltipContent.low }}
      </p>
    </div>
    <div class="w-1/2">
      <p class="font-bold">
        High:
      </p>
      <p
        v-if="typeof barTooltipContent.high === 'object'"
      >
        {{ barTooltipContent.high[0] }}<br>
        {{ barTooltipContent.high[1] }}
      </p>
      <p
        v-else
      >
        {{ barTooltipContent.high }}
      </p>
    </div>
  </div>
</template>

<script>

import { AbilityHighLow } from '@/content/reportContent.json'

export default {
  data () {
    return {
      tooltipStyle: {
        left: 0,
        top: 0
      },
      showTooltip: false,
      mouseOnTooltip: false,
      barTooltipContent: {
        high: '',
        low: ''
      }
    }
  },
  methods: {
    tooltipHandler (context) {
      const tooltipModel = context.tooltip
      if (tooltipModel.opacity === 0 && !this.mouseOnTooltip) {
        this.showTooltip = false
        return
      }

      const [low, high] = AbilityHighLow[tooltipModel.title[0]]
      this.barTooltipContent.low = low
      this.barTooltipContent.high = high

      const position = context.chart.canvas.getBoundingClientRect()

      this.showTooltip = true
      this.tooltipStyle.left = position.left + window.pageXOffset + 150 + 'px'

      if (tooltipModel.title[0] === 'Strategic Thinking') {
        this.tooltipStyle.top = (position.top + window.pageYOffset + tooltipModel.caretY) - 150 + 'px'
      } else {
        this.tooltipStyle.top = (position.top + window.pageYOffset + tooltipModel.caretY) - 14 + 'px'
      }
    }
  }
}
</script>

<style>

</style>
