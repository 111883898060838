
/*
COLORS
*/
export const attentionColor = 'rgb(95, 77, 62)'
export const shortTermMemoryColor = 'rgb(77, 83, 97)'
export const workingMemoryColor = 'rgb(79, 91, 117)'
export const impulseControlColor = 'rgb(31, 32, 37)'
export const creativityCognitiveFlexibilityColor = 'rgb(31, 32, 37)'
export const conceptualizationColor = 'rgb(180, 156, 121)'
export const strategicThinkingColor = 'rgb(180, 156, 121)'

export const estimateColor = 'rgb(162, 209, 248)'
export const maxColor = 'rgb(50, 125, 134)'

/*
MEASURES
*/

// MAIN
export const workingMemory = 'working_memory'
export const creativityCognitiveFlexibility = 'creativity_cognitive_flexibility'
export const creativityCognitiveFlexibilityEasy = 'creativity_cognitive_flexibility_easy'
export const creativityCognitiveFlexibilityHard = 'creativity_cognitive_flexibility_hard'
export const strategicThinking = 'strategic_thinking'
export const strategicThinkingEasy = 'strategic_thinking_easy'
export const strategicThinkingHard = 'strategic_thinking_hard'
export const conceptualization = 'conceptualization'
export const impulseControl = 'impulse_control'
export const impulseControlEasy = 'impulse_control_easy'
export const impulseControlhard = 'impulse_control_hard'
export const simpleAttention = 'simple_attention'
export const attentionControl = 'attention_control'
export const shortTermMemory = 'short_term_memory'

// QUALITY
export const accuracy = 'accuracy'
export const ruleFocus = 'rule_focus'
export const processSpeed = 'process_speed'
export const repetitionAvoidance = 'repetition_avoidance'

/*
SPIDER CHART

INDEX USED TO ORDER IN CHART
*/
export const spiderMeasures = [
  simpleAttention,
  attentionControl,
  shortTermMemory,
  workingMemory,
  impulseControl,
  creativityCognitiveFlexibility,
  conceptualization,
  strategicThinking
]

/*
QUALITY MEASURES
*/
export const qualityMeasures = [
  accuracy,
  ruleFocus,
  processSpeed,
  repetitionAvoidance
]

/*
EASY HARD MEASURES
*/

// export const easyHardMeasures = [
//     creativityCognitiveFlexibilityEasy,
//     creativityCognitiveFlexibilityHard,
//     strategicThinkingEasy,
//     strategicThinkingHard,
// ]
