<template>
  <div :class="setClass()">
    <div class="flex flex-row items-center mb-6">
      <p class="text-base font-semibold mb-0">
        {{ title }}
      </p>
      <svg
        class="mx-4"
        v-if="state == 'green' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#46634F"
        />
      </svg>
      <svg
        class="mx-4"
        v-if="state == 'yellow' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#E3C10C"
        />
      </svg>
      <svg
        class="mx-4"
        v-if="state == 'red' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#D31515"
        />
      </svg>
      <svg
        class="mx-4"
        v-if="state == 'orange' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#FFA500"
        />
      </svg>
    </div>
    <p class="text-base font-normal leading-8">
      {{ content }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {

  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    state: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      colorClass: 'candidate-info-summary-box-green'
    }
  },
  mounted () {
    this.setClass()
  },
  methods: {
    setClass () {
      if (this.state === 'green') {
        return 'candidate-info-summary-box-green'
      } else if (this.state === 'yellow') {
        return 'candidate-info-summary-box-yellow'
      } else if (this.state === 'orange') {
        return 'candidate-info-summary-box-orange'
      } else {
        return 'candidate-info-summary-box-red'
      }
    }
  }
}
</script>

<style>
.candidate-info-summary-box-green {
    border-radius: 5px;
    padding: 20px 20px;
    background-color: white;
    border-left: 19px solid #46634F;
    border-top: 1px solid #46634F;
    border-right: 1px solid #46634F;
    border-bottom: 1px solid #46634F;
    margin-bottom: 3rem;

}

.candidate-info-summary-box-yellow {
    border-radius: 5px;
    padding: 20px 20px;
    background-color: white;
    border-left: 19px solid #E3C10C;
    border-top: 1px solid #947C00;
    border-right: 1px solid #947C00;
    border-bottom: 1px solid #947C00;
    margin-bottom: 3rem;

}

.candidate-info-summary-box-red {
    border-radius: 5px;
    padding: 20px 20px;
    background-color: white;
    border-left: 19px solid #D31515;
    border-top: 1px solid #D31515;
    border-right: 1px solid #D31515;
    border-bottom: 1px solid #D31515;
    margin-bottom: 3rem;

}

.candidate-info-summary-box-orange {
    border-radius: 5px;
    padding: 20px 20px;
    background-color: white;
    border-left: 19px solid #FFA500;
    border-top: 1px solid #FFA500;
    border-right: 1px solid #FFA500;
    border-bottom: 1px solid #FFA500;
    margin-bottom: 3rem;

}
</style>
